<template>
  <div class="flex flex-col h-full lg:justify-center">
    <div class="mb-10 lg:mb-0">
      <h3 class="m-0 text-center text-3xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200">
        <p class="box-border m-0 border-solid" style="border-image: initial">{{ title }}</p>
      </h3>
      <ul class="flex flex-col justify-around w-full p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
        <li
          v-for="(m, index) in massages"
          :key="`${index}-${m.name}`"
          class="inline-flex flex-col justify-center items-center font-semibold text-left border-solid p-2 lg:p-4"
        >
          <span class="text-md">{{ m.name }}</span
          ><span class="text-xl">{{ m.price }}€</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    massages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style></style>
