<template>
  <div class="flex flex-col h-full items-center lg:justify-center">
    <div class="mb-8">
      <template v-for="(m, index) in massages">
        <h3 :key="`${index}-${m.name}`" class="m-0 mt-7 text-xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 lg:text-lg">
          <p class="box-border m-0 border-solid" style="border-image: initial">{{ m.name }}</p>
        </h3>
        <div :key="`${index}-${m.price}`" class="flex justify-center items-center mt-6 leading-7 text-gray-900 border-0 border-gray-200">
          <p class="box-border m-0 text-2xl font-semibold leading-none border-solid">{{ m.price }}€</p>
          <p class="box-border m-0 border-solid" style="border-image: initial">/ séance</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    massages: {
      type: Array,
      required: true
    }
  }
};
</script>

<style></style>
